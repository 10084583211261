import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../Css/Contact.css";
import Header from "./Header";
import Checkbox from "@mui/material/Checkbox";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import InstagramIcon from "@mui/icons-material/Instagram";
import emailjs, { send } from "@emailjs/browser";
import { Link } from "react-router-dom";
import toast, {Toaster} from "react-hot-toast";

const Contact = () => {
  const navigate= useNavigate();
  const label = { inputProps: { "aria-label": "Checkbox" } };
  const [value, setValue] = useState("");
  // console.log(value);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_op8w5dh",
        "template_docmatq",
        e.target,
        "JJxlnw-aAuldMolSj"
      )
      
      .then((result) => {
        toast.success("Successfully sent!");
      navigate(0);
        // console.log("send");
      })
      .catch((error) => {
        toast.error('This is an error!');
      });
  };

  return (
    <>
      <Header />

      <Toaster/>
      <div className="container text-center contact-start">
        <h3 className="pt-5 text-uppercase contact-heading">Contact us</h3>
        <p className="contact-text">We'd love to hear from you.</p>
        <p className="contact-tiny-text">
          Our bridal consultant is always ready to help you get the best out of
          your dress.
        </p>
      </div>

      <div className="container containerr">
        <div className="contact">
          <Link to="https://www.facebook.com/airvyatelier" target="_blank">
            <button className="contact-btn">
              <FacebookRoundedIcon className="me-2" />
              Message via Facebook
            </button>
          </Link>
          <Link to="https://www.instagram.com/airvyatelier" target="_blank">
            <button className="contact-btn">
              <InstagramIcon className="me-2" />
              Message via Instagram
            </button>
          </Link>
          <Link to="https://wa.me/2348053908869" target="_blank">
            <button className="contact-btn">
              <WhatsAppIcon className="me-2" />
              Message via Whatsapp
            </button>
          </Link>
        </div>
      </div>

      <div className="d-flex container divider justify-content-between">
        <div className="left mt-2"></div>
        <p>OR</p>
        <div className="right mt-2"></div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="container form">
          <div className="">
            <div className="name-email">
              <div className="name">
                <label className="mb-1">Name</label>
                <input
                  name="name"
                  type="text"
                  className="container mb-4"
                  placeholder="Your Name"
                />
              </div>

              <div className="email">
                <label className="mb-1">Email</label>
                <input
                  name="email"
                  type="email"
                  className="container mb-4"
                  placeholder="you@company.com"
                  required
                />
              </div>
            </div>

            <div className="">
              <label className="mb-1">Phone number</label>
              <PhoneInput
                value={value}
                country={"us"}
                onChange={setValue}
                className="mb-4"
              />
            </div>

            <div className="">
              <label className="mb-1">How can we help?</label>
              <textarea
                name="message"
                rows="5"
                className="mb-4 textt"
                placeholder="Tell us a little about the project."
              ></textarea>
            </div>

            <div className="">
              <label className="">Services</label>
              <div className="service-option-container">
                <div className="service-option">
                  <Checkbox {...label} style={{ color: "#575757" }} />
                  Consultation
                </div>
                <div className=" service-option">
                  <Checkbox {...label} style={{ color: "#575757" }} />
                  Enquiries
                </div>
                <div className="service-option">
                  <Checkbox {...label} style={{ color: "#575757" }} />
                  Rental
                </div>
              </div>
            </div>

            <button type="submit" className="last-contact-button">
              Send message
            </button>
          </div>
        </div>
      </form>
    </>
  );
};
export default Contact;
