import React, { Component } from "react";
import { NavLink, Link } from "react-router-dom";
import "../Css/Header.css";
import "../Css/TransparentHeader.css";
import "../Fonts/Isidora/IsidoraSans-Regular.ttf";

class Header extends Component {
  state = { clicked: false };
  handleClick = () => {
    this.setState({ clicked: !this.state.clicked });
  };

  render() {
    return (
      <>
        <header className="whiteHeader activee">
          <nav className="navbar-container">
            <div className="nav-logo-container">
              <img src="https://res.cloudinary.com/dtrxg5r8t/image/upload/v1705358222/Airvy-logo_qq4nzc.png" alt="aivys-logo" />
            </div>

            <div id="navbar-lists-container" className={this.state.clicked ? '#navbar-lists-container active' : '#navbar-lists-container'}>
              <div className="lists1">
                <NavLink
                  exact
                  to="/"
                  className="listItem1 active"
                  activeClassName="active"
                >
                  Home
                </NavLink>
                <NavLink
                  to="/about"
                  className="listItem1 active"
                  activeClassName="active"
                >
                  About
                </NavLink>
                <NavLink
                  to="/couture"
                  className="listItem1 active"
                  activeClassName="active"
                >
                  Couture Process
                </NavLink>
                <NavLink
                  to="/collection"
                  className="listItem1 active"
                  activeClassName="active"
                >
                  Collection
                </NavLink>
                <NavLink
                  to="/contact"
                  className="listItem1 active"
                  activeClassName="activeItem"
                >
                  Contact
                </NavLink>
              </div>

              <div className="BKappoint2">
            <Link className="btnText" to="/bookappointment">
              <button className="transparentheader-button">
                Book a Consultation
              </button>
            </Link>  
          </div>

              
            </div>

            <div className="BKappoint">
              <Link className="btnText" to="/bookappointment">
                <button className="header-button">
                  Book a Consultation
                </button>
              </Link> 
            </div>

            <div id="mobile2" onClick={this.handleClick}>
                <i
                  className={
                    this.state.clicked ? "fas fa-times" : "fas fa-bars"
                  }
                ></i>
              </div>
          </nav>
        </header>
      </>
    );
  }
}
export default Header;
