import React from "react";
import "../Css/Couture.css";
import Header from "./Header";
import { Link } from "react-router-dom";

const Couture = () => {
  return (
    <>
      <Header />

      <div className="container text-center couture-start">
        <h3 className="pt-5 text-uppercase couture-heading">
          Experience Bridal Couture
        </h3>
        <p className="text-capitalize couture-text ">
          Step Inside Our Creative Workshop
        </p>
        <p className="couture-tiny-text">Your Dress, Our Craft.</p>
      </div>

      <section className="desktop-view2 ">
        <div className=" mt-5 couture-process-container ">
       
        <div className="couture-img">
        <img src="https://res.cloudinary.com/dtrxg5r8t/image/upload/v1705930276/consultation_rnr3zu.jpg" className="container" alt=""/>
        </div>
     
          <div className="blur-box container">
            <p className="numbering">#1</p>
            <h1 className="couture-process">Consultation</h1>
            <p className="contentt">
              Let's Create Your Dream Dress Together! We'll discuss every detail, from color to fabric, in a free consultation with our Bridal consultant. If you prefer more in-depth guidance, choose
              our paid option with Evi. Consultation can be in-person, via email, WhatsApp, video call, or Google Meet. Don't worry if you're unsure; we're here to help you define your style!
            </p>
          </div>
        </div>

        <div className="couture-process-container2">
          <div className="blur-box container">
            <p className="numbering">#2</p>
            <h1 className="couture-process">Sketch</h1>
            <p className="contentt">
            Your Vision, Your Sketch! After our consultation, we'll craft a dress sketch inspired by your ideas. We'll share it via email or WhatsApp, and if needed, we'll make adjustments until it's just right. There's a non-refundable sketch fee, but it's deducted from your dress price.
            </p>
          </div>
          <div className="couture-img"><img src="https://res.cloudinary.com/dtrxg5r8t/image/upload/v1705358268/sketch_mycwmb.png" className="container" alt="" /></div>
          
          
        </div>

        <div className="couture-process-container">
        <div className="couture-img"><img src="https://res.cloudinary.com/dtrxg5r8t/image/upload/v1705930275/production_svvvat.jpg" className="container" alt="" /></div>
          
          <div className="blur-box container">
            <p className="numbering">#3</p>
            <h1 className="couture-process">Production</h1>
            <p className="contentt">
            Creating Your Dream Dress: Once your design and fabric are approved, we start crafting your dress. If you're abroad or can't visit, we can make a mock-up dress for fitting. The production typically takes six weeks, but it may vary based on design complexity.
            </p>
          </div>
        </div>

        <div className="couture-process-container2">
          <div className="blur-box container">
            <p className="numbering">#4</p>
            <h1 className="couture-process">Fittings</h1>
            <p className="contentt">
            Once the dress is partly ready, you'll be invited for a first fitting. Subsequent fittings may be necessary depending on the outcome of the first fitting to ensure a perfect fit of the garment, in some cases though a single fitting could be enough!
            </p>
          </div>
          <div className="couture-img"><img src="https://res.cloudinary.com/dtrxg5r8t/image/upload/v1705358255/fitting2_eputr4.png" className="container" alt="" /></div>
          
        </div>

        <div className="mb-5 couture-process-container">
        <div className="couture-img"><img src="https://res.cloudinary.com/dtrxg5r8t/image/upload/v1705930274/delivery_lrwqwg.jpg" className="container" alt="" /></div>
          
          <div className="blur-box container">
            <p className="numbering">#5</p>
            <h1 className="couture-process">Collection or Delivery</h1>
            <p className="contentt">
            Once the dress is fully finished, you can pick up at the studio or we can arrange a delivery to you anywhere in the world.
            </p>
          </div>
        </div>
      </section>

      <div className="container text-center couture-footer">
        <h3 className="tiny-text">
          Say 'I Do' to Expert Guidance
        </h3>
        <p className="big-text container">
          Design Your Dream Dress with a Designer, Chat with our Consultant for
          Free!
        </p>
        <div className="buttons">
        <Link to="https://calendly.com/airvyatelier/free-consultation" target="_blank">
        <button className="button">
          Consult for free
        </button>
        </Link>

        <Link to=" https://calendly.com/eviairvy/consult-with-evi" target="_blank">
        <button className="button2">
          Consult with evi
        </button>
        </Link>
        </div>
      </div>
    </>
  );
};
export default Couture;
