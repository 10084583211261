import React from "react";
import "../Css/Header.css";
import "../Css/Footer.css";
import { Link } from "react-router-dom";
import "../Fonts/Isidora/IsidoraSans-Regular.ttf";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import BackToTopButton from "./BackToTopButton";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const Footer = () => {
  return (
    <>
      <footer className="desktop-view">
        <div className="footer">
          <div className="foot">
            <div className="logo">
              <img
                src="https://res.cloudinary.com/dtrxg5r8t/image/upload/v1705358222/Airvy-logo_qq4nzc.png"
                alt="aivys-logo"
              />
            </div>

            <div className="footer-text">
              <h3 className="pt-3 text-uppercase footer-tiny-text">
                Walk in store
              </h3>
              <p className="footer-normal-text pt-2 mb-5">
                Airvy showroom, Lennox mall, admiralty way, lekki phase 1, Lagos
                State, Nigeria.
              </p>
            </div>
          </div>

          <BackToTopButton />

          <div className="borderBottom"></div>

          <div className="copyright-container mt-4">
            <div className="copyright">
              <p>&copy; 2023 Airvy. All rights reserved.</p>
            </div>

            <nav>
              <div className="icons d-flex">
                <Link
                  to="https://www.instagram.com/airvyatelier"
                  target="_blank"
                >
                  <InstagramIcon
                    style={{ color: "#252525" }}
                    className="mr-3"
                  />
                </Link>
                <Link
                  to="https://www.facebook.com/airvyatelier"
                  target="_blank"
                >
                  <FacebookIcon style={{ color: "#252525" }} className="mx-3" />
                </Link>
                <Link to="https://wa.me/2348053908869" target="_blank">
                  <WhatsAppIcon style={{ color: "#252525" }} />
                </Link>
              </div>
            </nav>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
