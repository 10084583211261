import React from "react";
import Header from "./Header";
import "../Css/BookAppointment.css";
import "../App.css";
import { useState } from "react";
import { Link } from "react-router-dom";

const BookAppointment = () => {
  const [toggle, setToggle] = useState(1);
  function updateToggle(id) {
    setToggle(id);
  }

  return (
    <>
      <Header />

      <div className="BA-container">
        <div className="container text-center">
          <h3 className="text-uppercase couture-heading BA-heading">
            Experience Bridal Couture
          </h3>
          <h3 className="pt-1 home-heading BA-big-heading">
            Your Appointment Awaits
          </h3>
          <p className="BA-text">
            Are you on the journey to finding the perfect wedding gown? We're
            here to help! At Airvy, we offer personalized bridal dress
            consultations to bring your dream wedding look to life. Our Lead
            Designer is devoted to ensuring your bridal journey is not only
            seamless but also eternally memorable.
          </p>
        </div>
        <div className="d-flex justify-content-around tabs">
          <div
            className={`${toggle === 1 ? `tab active-tabb list` : `tab list`}`}
            onClick={() => updateToggle(1)}
          >
            <p>Our Designer</p>
          </div>
          <div
            className={`${toggle === 2 ? `tab active-tabb list` : `tab list`}`}
            onClick={() => updateToggle(2)}
          >
            <p>Bridal Consultant</p>
          </div>
        </div>
      </div>

      <div className="border-bottom"></div>

      <div className="container text-center mt-4">
        <div className={toggle === 1 ? "show-content" : "consult content"}>
          <div className="container d-flex flex-column ">
            <h3 className="pt-2 designer-heading text-center">
              Consult with the Designer
            </h3>
            <p className="designer-price text-center">30USD</p>
          </div>

          <div className="d-flex flex-column justify-content-center">
            <div className="container">
              <p className="BA-text1">
                Elevate your bridal experience with our exclusive consultation
                option—your chance to work directly with our renowned Top
                Designer.
              </p>
              <p className="BA-text1">
                During your consultation, you'll have the opportunity to:
              </p>
              <ul className="BA-text1 container">
                <li>Explore our stunning collection of wedding dresses.</li>
                <li>
                  Discuss your style preferences, from classic to contemporary.
                </li>
                <li>Receive expert advice on fit, fabrics, and trends.</li>
                <li>Get recommendations tailored to your unique vision.</li>
              </ul>
              <p className=" BA-text1">
                We can't wait to be a part of your bridal journey and help you
                find the gown of your dreams.
              </p>

              <Link
                to="https://calendly.com/eviairvy/consult-with-evi"
                target="_blank"
              >
                <button className="last-contact-button1 container">
                  Consult with Evi
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="container text-center mt-4 ">
        <div className={toggle === 2 ? "show-content" : "content"}>
          <h3 className="pt-1 home-heading BA-big-heading2">
            Let's pair you with one of our bridal consultants
          </h3>
          <p className="BA-text1">
            Let our dedicated team be your trusted companion on this special
            journey, offering personalized guidance and support as you navigate
            through the world of bridal fashion.
          </p>
          <p className="BA-text1">
            Say "yes" to your dream dress without any hesitation or cost-it's
            all part of our commitment to making your wedding dreams a reality.
          </p>
          <Link
            to="https://calendly.com/airvyatelier/free-consultation"
            target="_blank"
          >
            <button className="last-contact-button1 container">
              Consult for free
            </button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default BookAppointment;
