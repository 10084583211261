import React, { useState } from "react";
import Header from "./Header";
import "../Css/Store.css";
import "../Css/Home.css";

const Store = () => {
  // console.log(Data);
  const [toggle, setToggle] = useState(1);
  function updateToggle(id) {
    setToggle(id);
  }
  return (
    <>
      <Header />

      <div className="subNav">
        <div className="d-flex justify-content-around align-items-center storeNav">
          <div
            className={`${toggle === 1 ? `tab active-tab list` : `tab list`}`}
            onClick={() => updateToggle(1)}
          >
            <p> Ball gown</p>
          </div>
          <div
            className={`${toggle === 2 ? `tab active-tab list` : `tab list`}`}
            onClick={() => updateToggle(2)}
          >
            <p>Trumpet gown</p>
          </div>
          <div
            className={`${toggle === 3 ? `tab active-tab list` : `tab list`}`}
            onClick={() => updateToggle(3)}
          >
            <p>Mermaid gown</p>
          </div>
        </div>
      </div>
      <div className="border-bottom"></div>

      <section className="mt-5 gallery2" id="weddingGown">
        <div className={toggle === 1 ? "imgContainer col" : "content"}>
          <div className="roww">
            <img
              src="https://res.cloudinary.com/dtrxg5r8t/image/upload/v1705358238/evi4_u4oaig.jpg"
              alt="ball-gown"
              className=""
            />
            <img
              src="https://res.cloudinary.com/dtrxg5r8t/image/upload/v1705358239/evi5_ourd4b.jpg"
              alt="ball-gown"
              className=""
            />
            <img
              src="https://res.cloudinary.com/dtrxg5r8t/image/upload/v1705358241/evi6_ebgj4p.jpg"
              alt="ball-gown"
              className=""
            />
          </div>

          <div className="container text-center mt-5 store">
            <h3 className=" text-uppercase tiny-heading">Accesories</h3>
            <h3 className="heading">Complete the look</h3>
            <p className="text pb-5">
              Our walk in store is available & open to every bride, walk in and
              find just the right accessories for your wonderful day.
            </p>
          </div>

          <div className="gallery-accessory">
            <div className={toggle === 1 ? "col" : "content"}>
              <img
                src="https://res.cloudinary.com/dtrxg5r8t/image/upload/v1705358206/access1_maqoim.png"
                alt="access1"
                data-bs-toggle="modal"
                className="img cursor-pointer"
                data-bs-target="#image"
              />
            </div>
            <div className="col">
              <img
                src="https://res.cloudinary.com/dtrxg5r8t/image/upload/v1705358208/access2_rhqqms.png"
                alt="access2"
                data-bs-toggle="modal"
                className="img cursor-pointer"
                data-bs-target="#image2"
              />
            </div>
            <div className="col">
              <img
                src="https://res.cloudinary.com/dtrxg5r8t/image/upload/v1705358209/access3_tzbi3j.png"
                alt="access3"
                data-bs-toggle="modal"
                className="img cursor-pointer"
                data-bs-target="#image3"
              />
            </div>
            <div className="col">
              <img
                src="https://res.cloudinary.com/dtrxg5r8t/image/upload/v1705358211/access4_jls8dc.png"
                alt="access4"
                data-bs-toggle="modal"
                className="img cursor-pointer"
                data-bs-target="#image4"
              />
            </div>

            <div className="modal fade" id="image" tabIndex="-1" aria-hidden>
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-body">
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                    <img
                      src="https://res.cloudinary.com/dtrxg5r8t/image/upload/v1705358206/access1_maqoim.png"
                      alt="access1"
                      className="d-block w-100"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="modal fade" id="image2" tabIndex="-1" aria-hidden>
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-body">
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                    <img
                      src="https://res.cloudinary.com/dtrxg5r8t/image/upload/v1705358208/access2_rhqqms.png"
                      alt="access2"
                      className="d-block w-100"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="modal fade" id="image3" tabIndex="-1" aria-hidden>
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-body">
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                    <img
                      src="https://res.cloudinary.com/dtrxg5r8t/image/upload/v1705358209/access3_tzbi3j.png"
                      alt="access3"
                      className="d-block w-100"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="modal fade" id="image4" tabIndex="-1" aria-hidden>
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-body">
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                    <img
                      src="https://res.cloudinary.com/dtrxg5r8t/image/upload/v1705358211/access4_jls8dc.png"
                      alt="access4"
                      className="d-block w-100"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="gallery2">
        <div className={toggle === 2 ? "imgContainer col" : "content"}>
          <div className="roww">
            <img
              src="https://res.cloudinary.com/dtrxg5r8t/image/upload/v1705358250/evi16_c3x1bi.jpg"
              alt="detachable-wrap"
              className=""
            />
            <img
              src="https://res.cloudinary.com/dtrxg5r8t/image/upload/v1705358249/evi15_s93yrc.jpg"
              alt="detachable-wrap"
              className=""
            />
            <img
              src="https://res.cloudinary.com/dtrxg5r8t/image/upload/v1705358248/evi14_nckjoh.jpg"
              alt="detachable-wrap"
              className=""
            />
            <img
              src="https://res.cloudinary.com/dtrxg5r8t/image/upload/v1705358237/evi3_pdokb8.jpg"
              alt="detachable-wrap"
              className=""
            />
            <img
              src="https://res.cloudinary.com/dtrxg5r8t/image/upload/v1705358234/evi1_vqiksc.jpg"
              alt="detachable-wrap"
              className=""
            />
            <img
              src="https://res.cloudinary.com/dtrxg5r8t/image/upload/v1705358236/evi2_tevef6.jpg"
              alt="detachable-wrap"
              className=""
            />
          </div>

          <div className="container text-center mt-5 store">
            <h3 className=" text-uppercase tiny-heading">Accesories</h3>
            <h3 className="heading">Complete the look</h3>
            <p className="text pb-5">
              Our walk in store is available & open to every bride, walk in and
              find just the right accessories for your wonderful day.
            </p>
          </div>

          <div className="gallery-accessory">
            <div className={toggle === 2 ? "col" : "content"}>
              <img
                src="https://res.cloudinary.com/dtrxg5r8t/image/upload/v1705358211/access5_i1twze.png"
                alt="access5"
                data-bs-toggle="modal"
                className="img cursor-pointer"
                data-bs-target="#image5"
              />
            </div>
            <div className="col">
              <img
                src="https://res.cloudinary.com/dtrxg5r8t/image/upload/v1705358212/access6_eooozk.png"
                alt="access6"
                data-bs-toggle="modal"
                className="img cursor-pointer"
                data-bs-target="#image6"
              />
            </div>
            <div className="col">
              <img
                src="https://res.cloudinary.com/dtrxg5r8t/image/upload/v1705358213/access7_o2pfay.png"
                alt="access7"
                data-bs-toggle="modal"
                className="img cursor-pointer"
                data-bs-target="#image7"
              />
            </div>
            <div className="col">
              <img
                src="https://res.cloudinary.com/dtrxg5r8t/image/upload/v1705358216/access8_htecsn.png"
                alt="access8"
                data-bs-toggle="modal"
                className="img cursor-pointer"
                data-bs-target="#image8"
              />
            </div>

            <div className="modal fade" id="image5" tabIndex="-1" aria-hidden>
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-body">
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                    <img
                      src="https://res.cloudinary.com/dtrxg5r8t/image/upload/v1705358211/access5_i1twze.png"
                      alt="access5"
                      className="d-block w-100"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="modal fade" id="image6" tabIndex="-1" aria-hidden>
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-body">
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                    <img
                      src="https://res.cloudinary.com/dtrxg5r8t/image/upload/v1705358212/access6_eooozk.png"
                      alt="access6"
                      className="d-block w-100"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="modal fade" id="image7" tabIndex="-1" aria-hidden>
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-body">
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                    <img
                      src="https://res.cloudinary.com/dtrxg5r8t/image/upload/v1705358213/access7_o2pfay.png"
                      alt="access7"
                      className="d-block w-100"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="modal fade" id="image8" tabIndex="-1" aria-hidden>
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-body">
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                    <img
                      src="https://res.cloudinary.com/dtrxg5r8t/image/upload/v1705358216/access8_htecsn.png"
                      alt="access8"
                      className="d-block w-100"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="gallery2">
        <div className={toggle === 3 ? "imgContainer col" : "content"}>
          <div className="roww">
            <img
              src="https://res.cloudinary.com/dtrxg5r8t/image/upload/v1705358243/evi8_xt4el5.jpg"
              alt="mermaid"
              className=""
            />
            <img
              src="https://res.cloudinary.com/dtrxg5r8t/image/upload/v1705358241/evi7_joasrk.jpg"
              alt="mermaid"
              className=""
            />
            <img
              src="https://res.cloudinary.com/dtrxg5r8t/image/upload/v1705358244/evi9_i7p5gr.jpg"
              alt="mermaid"
              className=""
            />
            <img
              src="https://res.cloudinary.com/dtrxg5r8t/image/upload/v1705358246/evi13_ivm4b3.jpg"
              alt="mermaid"
              className=""
            />
            <img
              src="https://res.cloudinary.com/dtrxg5r8t/image/upload/v1705358244/evi11_os1ymc.jpg"
              alt="mermaid"
              className=""
            />
            <img
              src="https://res.cloudinary.com/dtrxg5r8t/image/upload/v1705358245/evi12_zxjten.jpg"
              alt="mermaid"
              className=""
            />
          </div>

          <div className="container text-center mt-5 store">
            <h3 className=" text-uppercase tiny-heading">Accesories</h3>
            <h3 className="heading">Complete the look</h3>
            <p className="text pb-5">
              Our walk in store is available & open to every bride, walk in and
              find just the right accessories for your wonderful day.
            </p>
          </div>

          <section className="gallery-accessory" id="accessory">
            <div className={toggle === 3 ? "col" : "content"}>
              <img
                src="https://res.cloudinary.com/dtrxg5r8t/image/upload/v1705358216/access9_qz7jyv.png"
                alt="access9"
                data-bs-toggle="modal"
                className="img cursor-pointer"
                data-bs-target="#image9"
              />
            </div>
            <div className="col">
              <img
                src="https://res.cloudinary.com/dtrxg5r8t/image/upload/v1705358217/access10_r0rqnp.png"
                alt="access10"
                data-bs-toggle="modal"
                className="img cursor-pointer"
                data-bs-target="#image10"
              />
            </div>
            <div className="col">
              <img
                src="https://res.cloudinary.com/dtrxg5r8t/image/upload/v1705358218/access11_xtvaoi.png"
                alt="access11"
                data-bs-toggle="modal"
                className="img cursor-pointer"
                data-bs-target="#image11"
              />
            </div>
            <div className="col">
              <img
                src="https://res.cloudinary.com/dtrxg5r8t/image/upload/v1705358217/access12_lupynu.png"
                alt="access12"
                data-bs-toggle="modal"
                className="img cursor-pointer"
                data-bs-target="#image12"
              />
            </div>

            <div className="modal fade" id="image9" tabIndex="-1" aria-hidden>
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-body">
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                    <img
                      src="https://res.cloudinary.com/dtrxg5r8t/image/upload/v1705358216/access9_qz7jyv.png"
                      alt=""
                      className="d-block w-100"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="modal fade" id="image10" tabIndex="-1" aria-hidden>
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-body">
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                    <img
                      src="https://res.cloudinary.com/dtrxg5r8t/image/upload/v1705358217/access10_r0rqnp.png"
                      alt=""
                      className="d-block w-100"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="modal fade" id="image11" tabIndex="-1" aria-hidden>
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-body">
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                    <img
                      src="https://res.cloudinary.com/dtrxg5r8t/image/upload/v1705358218/access11_xtvaoi.png"
                      alt=""
                      className="d-block w-100"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="modal fade" id="image12" tabIndex="-1" aria-hidden>
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-body">
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                    <img
                      src="https://res.cloudinary.com/dtrxg5r8t/image/upload/v1705358217/access12_lupynu.png"
                      alt=""
                      className="d-block w-100"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};
export default Store;
