import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

const InstagramFeed = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    // Your Instagram Access Token
    const accessToken =
      "IGQWROYkJBRWdPaE9MTm55NHZAqVFprUTdDdzVFVmNFSkxZAbnBRYjdGdmVkeWtLVFNkNDdDeDZA6bXdPZAjhVR0VEaW9Tb2pGbWJ6NzJlai1KZAVZAGNnRTM2tpMG5rZA1lVUVlwWGZA3a3Y2TjB0dzVWZAkdpWDdNbzkydVEZD";

    // Fetch Instagram posts
    const fetchInstagramPosts = async () => {
      try {
        const response = await axios.get(
          `https://graph.instagram.com/v12.0/me/media?fields=id,caption,media_type,media_url,thumbnail_url,permalink,timestamp&access_token=${accessToken}&limit=3`
        );

        if (!response.data || !response.data.data) {
          throw new Error("Failed to fetch Instagram posts");
        }

        // Filter out posts with media_type "VIDEO"
        const filteredPosts = response.data.data.filter(
          (post) => post.media_type === "VIDEO"
        );

        console.log("first", response.data.data);
        setPosts(filteredPosts);
        // setPosts(response.data.data);
      } catch (error) {
        console.error("Error fetching Instagram posts:", error.message);
      }
    };

    fetchInstagramPosts();
  }, []);

  return (
    <div className="instagram-feed-container ">
      <div className="instagram-feed">
        {posts.map((post) => (
          <div key={post.id} className="instagram-post roww2">
            <img src={post.thumbnail_url} alt={post.caption} />

            <div className="insta-click-btn">
              <Link to={post.permalink} target="_blank">
                <button className="insta-btn">View IG post</button>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InstagramFeed;
