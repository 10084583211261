import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../Css/Home.css";
import Header from "./Header";
import "react-multi-carousel/lib/styles.css";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import BackToTopButton from "./BackToTopButton";
import InstagramFeed from "./Instafeeds";

const Home = () => {
  const [hover, setHover] = useState(true);
  const [hover2, setHover2] = useState(true);

  const handleHover = () => {
    setHover(false);
  };
  const mouseLeave = () => {
    setHover(true);
  };
  const handleHover2 = () => {
    setHover2(false);
  };
  const mouseLeave2 = () => {
    setHover2(true);
  };
  // const videoRef = useRef(null);
  // useEffect(() => {
  //   videoRef.current.play();
  // }, []);

  return (
    <>
    
      <Header/>
      {/* <video ref={videoRef} autoPlay muted loop playsInline className="video">
        <source
          src="https://res.cloudinary.com/dtrxg5r8t/video/upload/v1705358283/airvy_aahl4z.mp4"
          type="video/mp4"
        />
      </video> */}
      
      <div className="container vid-text">
        <h3 className="text-uppercase text-center">Airvy</h3>
        <p>Bespoke and ready to wear dresses by Airvy for every woman!</p>
      </div>
      <div className="disappear-container">
        <div
          className="disappearing-subcontainer column2"
          onMouseLeave={mouseLeave}
          onMouseEnter={handleHover}
        >
          <div className="img ">
            <img
              src="https://res.cloudinary.com/dtrxg5r8t/image/upload/v1705358243/evi8_xt4el5.jpg"
              alt=""
              className=""
            />

            <div
              className={hover ? "disappear " : "disappearing-text overlayy"}
            >
              <div className="text"> Wedding Gowns </div>
              <div className="discover-btn">
                <Link to="/collection#weddingGown">
                  <button className="btn2">
                    Discover
                    <ArrowOutwardIcon
                      className="arrow"
                      style={{ color: "#060503" }}
                    />
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div
          className="disappearing-subcontainer column2"
          onMouseLeave={mouseLeave2}
          onMouseEnter={handleHover2}
        >
          <div className="img">
            <img
              src="https://res.cloudinary.com/dtrxg5r8t/image/upload/v1705358221/accessory_uaxbwd.png"
              alt=""
              className=""
            />

            <div
              className={hover2 ? "disappear" : "disappearing-text overlayy"}
            >
              <div className="text">Accessories</div>
              <div className="discover-btn">
                <Link to="/collection#accessory">
                  <button className="btn2">
                    Discover
                    <ArrowOutwardIcon
                      className="arrow"
                      style={{ color: "#060503" }}
                    />
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="disappear-container-mobile-view">
        <div className="homeBG1 pb-2">
          <div className="homeBG1-content">
            <p className="pb-1">wedding gowns</p>

            <div className="collection-button">
              <button className="btn2">
                <Link to="/collection#weddingGown" className="link">
                  Discover
                  <img
                    src="https://res.cloudinary.com/dtrxg5r8t/image/upload/v1705358229/Button_u8eu5s.png"
                    alt=""
                  />
                </Link>
              </button>
            </div>
          </div>
        </div>
        <div className="homeBG2 pb-2">
          <div className="homeBG2-content">
            <p className="pb-1">Accessories</p>

            <div className="collection-button ">
              <button className="btn2">
                <Link to="/collection#accessory" className="link">
                  Discover
                  <img
                    src="https://res.cloudinary.com/dtrxg5r8t/image/upload/v1705358229/Button_u8eu5s.png"
                    alt="arrow"
                  />
                </Link>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container text-center collection">
        <h3 className="home-tiny-heading ">And then some</h3>
        <h3 className="home-heading">Airvy Brides</h3>
      </div>
      <div className="roww">
        <img
          src="https://res.cloudinary.com/dtrxg5r8t/image/upload/v1705358200/1_hmd3yc.png"
          alt=""
          className="image"
        />
        <img
          src="https://res.cloudinary.com/dtrxg5r8t/image/upload/v1705358201/2_laxwkv.png"
          alt=""
          className="image"
        />
        <img
          src="https://res.cloudinary.com/dtrxg5r8t/image/upload/v1705358201/3_v0s1hn.png"
          alt=""
          className="image"
        />
      </div>
      <div className="roww row-2">
        <img
          src="https://res.cloudinary.com/dtrxg5r8t/image/upload/v1705358203/4_czamgn.png"
          alt=""
          className="image"
        />
        <img
          src="https://res.cloudinary.com/dtrxg5r8t/image/upload/v1705358202/5_i1kizn.png"
          alt=""
          className="image"
        />
        <img
          src="https://res.cloudinary.com/dtrxg5r8t/image/upload/v1705358204/6_vjd9xs.png"
          alt=""
          className="image"
        />
      </div>
      <div className="container text-center home-feedback">
        <h3 className="heading1">Feedback</h3>
        <h3 className="pt-1 heading2">Love letters from brides</h3>
      </div>
      <div className="feedback-container container">
        <div className="feedback-comment-container1 left">
          <div className="comment one first">
            <p>
              Everyone Was Talking About The Dress, Thank You For Making Me A
              Beautiful Bride
            </p>

            <div className="image-ratings-container">
              <img
                src="https://res.cloudinary.com/dtrxg5r8t/image/upload/v1705358224/Avatar1_ex304a.png"
                alt=""
              />
              <div className="image-ratings">
                <p>
                  Beatrice
                  <br />
                  <span style={{ fontSize: "100%", color: "black" }}>
                    &#9733;&#9733;&#9733;&#9733;&#9733;
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div className="comment one">
            <p>
              This one is dedicated to the hands that created all my magical
              outfits. My wedding dress and my reception. Airvyatelier. <br />{" "}
              <br />
              Eviliii Evii, you are patient, you listen, there is no it can't be
              done in your dictionary. Is that what I want? Let's create it.
              Thank you for giving me outfits that made me comfortable.
            </p>

            <div className="image-ratings-container">
              <img
                src="https://res.cloudinary.com/dtrxg5r8t/image/upload/v1705358227/Avatar2_iyyjfc.png"
                alt=""
              />
              <div className="image-ratings">
                <p>
                  Funmi <br />
                  <span style={{ fontSize: "100%", color: "black" }}>
                    &#9733;&#9733;&#9733;&#9733;&#9733;
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="feedback-comment-container2 right">
          <div className="two">
            <p>
              Just wanted to send my proper review. <br /> <br />
              So firstly, it was about 3 months to my wedding and wasn't close
              to getting a wedding dress vendor&#128549;&#128549;&#128549;{" "}
              <br /> <br />
              I sent a "copy and paste" text to about 4 vendors, Airvy included
              only verifying if they were within my budget. <br /> <br />
              Lowkey, in order of priority, Airvy was my first option then I
              discovered that consultation was free&#129402;&#129402;&#129402;.{" "}
              <br />
              Even though my "then fiancé" still complained about the expense, I
              was just enjoying the superb customer service. <br /> <br />
              Then first fitting day. I wept&#128549;&#128549;&#128549;. The
              dress was perfect! And even though there was a lot of back and
              forth with the church, I'm glad I didn't alter it anymore. It was
              super stunning! It was me. It hugged me in all the right places
              and I could even carry it without any extra help. <br /> <br />
              Thank you, Airvy. I loved my wedding dress. Thank you
              &#129505;&#129392;&#129505;.
            </p>

            <div className="image-ratings-container">
              <img
                src="https://res.cloudinary.com/dtrxg5r8t/image/upload/v1705358228/Avatar3_b2fork.png"
                alt=""
              />
              <div className="image-ratings">
                <p>
                  Oluwagbemileke
                  <br />
                  <span style={{ fontSize: "100%", color: "black" }}>
                    &#9733;&#9733;&#9733;&#9733;&#9733;
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container text-center home-container">
        <h3 className="text-uppercase tiny-text tiny-textt">
          Say 'I Do' to Expert Guidance
        </h3>
        <p className="big-text big-textt">
          Design Your Dream Dress with a Designer, Chat with our Consultant for
          Free!
        </p>

        <div className="buttonns">
          <Link
            to="https://calendly.com/airvyatelier/free-consultation"
            target="_blank"
          >
            <button className="buttonn1">Consult for free</button>
          </Link>

          <Link
            to="https://calendly.com/eviairvy/consult-with-evi"
            target="_blank"
          >
            <button className="buttonn2">Consult with evi</button>
          </Link>
        </div>
      </div>
      <div className="couture-process-container designer-container">
        <img
          src="https://res.cloudinary.com/dtrxg5r8t/image/upload/v1705358222/airvy2_m2v12f.png"
          className="couture-img container"
          alt=""
        />
        <div className="blur-box">
          <h3>The designer</h3>
          <h1>Evi Jesumuyiwa</h1>
          <p className="contentt container">
            Airvy atelier was established in 2015 by Evi Jesumuyiwa. She began
            designing as a teen in high school, creating sketches and making
            little dresses from scrap pieces she could find.
          </p>

          <div className="designer mt-5">
            <Link to="/about#theDesigner">
              <button className="btn2 designer-btn">
                Discover
                <ArrowOutwardIcon
                  className="arrow"
                  style={{ color: "#fdf0d5" }}
                />
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="container text-center insta-feed">
        <h3 className="text-uppercase couture-heading insta-post">
          Recent post
        </h3>
        <h3 className="home-heading">instagram feed</h3>
      </div>
      <InstagramFeed />
      <BackToTopButton />;
    </>
  );
};
export default Home;
